import { TIMEOUT_SEC, GEO_GOO } from "./config.js";

export const timeoutPromise = async function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject({ status: "NOT OK", msg: "Request timed-out", data: {} });
    }, s * 1000);
  });
};

export const AJAX = async function (url, meta0, uploadData0 = undefined) {
  let data0 = { ...meta0, ...uploadData0 };
  try {
    const fetchPromise = fetch(url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      crossorigin: "anonymous",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data0),
    });

    const res = await Promise.race([fetchPromise, timeoutPromise(TIMEOUT_SEC)]);

    const data = await res.json();

    if (res.status >= 400) throw data;
    if (!res.ok)
      throw {
        status: "NOT OK",
        msg: "Faulty response from the server",
        data: { HTTPcode: res.status },
      };

    return data;
  } catch (e) {
    throw e;
  }
};

export const AJAXexternal = async function (url, uploadData0 = undefined) {
  let query = "?";
  for (let d in uploadData0) {
    query +=
      encodeURIComponent(d) + "=" + encodeURIComponent(uploadData0[d]) + "&";
  }
  query = query.slice(0, -1);
  url = url + query;

  try {
    const fetchPromise = fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {},
    });

    const res = await Promise.race([fetchPromise, timeoutPromise(TIMEOUT_SEC)]);
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    const data = await res.json();
    return data;
  } catch (err) {
    throw err;
  }
};

export const AJAXpublic = async function (url, meta0, uploadData0 = undefined) {
  let data0 = { ...meta0, ...uploadData0 };
  try {
    const fetchPromise = fetch(url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      crossorigin: "anonymous",
      body: JSON.stringify(data0),
    });

    const res = await Promise.race([fetchPromise, timeoutPromise(TIMEOUT_SEC)]);

    const data = await res.json();

    if (res.status >= 400) throw data;
    if (!res.ok)
      throw {
        status: "NOT OK",
        msg: "Faulty response from the server",
        data: { HTTPcode: res.status },
      };

    return data;
  } catch (e) {
    throw e;
  }
};

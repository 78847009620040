import { AJAX, timeoutPromise } from "./helpers.js";
import { API_URL } from "./config.js";

const loginElement = document.querySelector("#loginName");
const passwordElement = document.querySelector("#passwordName");
const tournamentElement = document.querySelector("#tournamentName");
const errorElement = document.querySelector("#err-label");
const cookieAllowButt = document.querySelector("#allow");
const cookieNoAllowButt = document.querySelector("#noallow");
const loginButton = document.querySelector("#loginButton");
const cookieBanner = document.querySelector("#cookie-banner");
const cookieSettings = document.querySelector("#cookie-settings");

function showCookiesBanner() {
  setCookie(30, "cookiesAllow", 0);
  cookieBanner.style.animation = "none";
  allowCookiesOrNot();
}

function getCookie(name) {
  const cookies = document.cookie.split(";");

  if (cookies[0].length > 0) {
    for (let i = 0; i < cookies.length; i++) {
      const [cookieName, cookieValue] = cookies[i].split("=");
      const decodedCookieName = decodeURIComponent(cookieName.trim());
      const decodedCookieValue = decodeURIComponent(cookieValue.trim());

      if (decodedCookieName === name) {
        return decodedCookieValue;
      }
    }
  }

  // Cookie not found
  return null;
}
function setCookie(days0, cookieName0, cookieValue0) {
  var d = new Date();
  d.setTime(d.getTime() + days0 * 24 * 60 * 60 * 1000); // expires in days0 days
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cookieName0 + "=" + cookieValue0 + ";" + expires + ";path=/";
}

const allowCookies = async function () {
  try {
    const persistedData = await AJAX(`${API_URL}cookies/`, null, {
      answer: 1,
    });

    const cookieAllowed = getCookie("cookiesAllow");

    if (cookieAllowed > 0) {
      setCookie(30, "cookiesAllow", 1);
      cookieBanner.style.display = "none";
      cookieSettings.style.display = "flex";
    }
  } catch (err) {
    alert("DENNIS error:" + err.msg);
  }
};
const allowCookiesOrNot = async function () {
  try {
    const cookieCookie = getCookie("cookiesAllow");

    if (cookieCookie) {
      if (Number(cookieCookie) === 0) {
        cookieSettings.style.display = "none";
        cookieBanner.style.display = "flex";
      } else {
        if (Number(cookieCookie) === 1) {
          cookieBanner.style.display = "none";
          cookieSettings.style.display = "flex";
        } else {
          cookieSettings.style.display = "none";
          cookieBanner.style.display = "flex";
        }
      }
    } else {
      cookieSettings.style.display = "none";
      cookieBanner.style.display = "flex";
    }
  } catch (err) {
    throw e;
  }
};
const allowNotCookies = async function () {
  try {
    const persistedData = await AJAX(`${API_URL}cookies/`, null, {
      answer: -1,
    });

    const cookieAllowed = getCookie("cookiesAllow");
    if (cookieAllowed < 0) {
      setCookie(30, "cookiesAllow", -1);
      cookieBanner.style.display = "none";
      cookieSettings.style.display = "flex";
    }
  } catch (err) {
    alert("DENNIS error:" + err.msg);
    // throw err;
  }
};
const login = async function (loginData0) {
  try {
    const persistedData = await AJAX(`${API_URL}login/`, null, loginData0);

    return persistedData;
  } catch (err) {
    throw err;
  }
};
const loginDataPrepare = async function () {
  const loginNameValue = loginElement.value.trim();
  const passwordNameValue = passwordElement.value.trim();
  const tournamentNameValue = tournamentElement.value.trim();
  if (
    loginNameValue.length > 0 &&
    passwordNameValue.length > 0 &&
    tournamentNameValue.length > 0
  ) {
    const loginObj = { loginNameValue, passwordNameValue, tournamentNameValue };

    try {
      const cookiesAllowed = getCookie("cookiesAllow");
      if (cookiesAllowed < 1) {
        errorElement.innerText = "You need to allow cookies to log in";
        return;
      }
      let loginData = await login(loginObj);
      if (loginData.status == "NOT OK") {
        errorElement.innerText = loginData.msg;
      } else {
        localStorage.setItem("login", loginData.data.login);
        localStorage.setItem("tour", loginData.data.tour);
        location.assign(API_URL);
      }
    } catch (err) {
      errorElement.style =
        "grid-column: 2/3;grid-row: 4/5;color: var(--color-selected);";
      errorElement.innerText = err.msg;
    }
  } else {
    errorElement.style =
      "grid-column: 2/3;grid-row: 4/5;color: var(--color-selected);";
    errorElement.innerText = "Incomplete data";
  }
};
const main = async function () {
  await loginDataPrepare();
};
loginButton.addEventListener("click", main);
cookieAllowButt.addEventListener("click", allowCookies);
cookieNoAllowButt.addEventListener("click", allowNotCookies);
cookieSettings.addEventListener("click", showCookiesBanner);

if (localStorage.getItem("login"))
  loginElement.value = localStorage.getItem("login");

if (localStorage.getItem("tour"))
  tournamentElement.value = localStorage.getItem("tour");

allowCookiesOrNot();
